import { isMobile } from '../../utils';

export const initialSettings = {
  trackSwitchOffMode: undefined,
  dominantSpeakerPriority: 'standard',
  bandwidthProfileMode: 'collaboration',
  maxTracks: isMobile ? '5' : '10',
  maxAudioBitrate: '16000',
  renderDimensionLow: 'low',
  renderDimensionStandard: '960p',
  renderDimensionHigh: 'wide1080p',
  symblAppId:  '4a49495a7269714473314b6b49307632556d4d6d54413675314b50786b434a33',
  symblAppSecret: '304470624e5137496f5371584854366a345f6266596e4d6277456b32536d45575a56456739397171414a7a694e6e4a614b464a4f79536f756236494e7673484b'
};

// This inputLabels object is used by ConnectionOptions.js. It is used to populate the id, name, and label props
// of the various input elements. Using a typed object like this (instead of strings) eliminates the possibility
// of there being a typo.
export const inputLabels = (() => {
  const target = {};
  for (const setting in initialSettings) {
    target[setting] = setting;
  }
  return target;
})();

export function settingsReducer(state, action) {
  return {
    ...state,
    [action.name]: action.value === 'default' ? undefined : action.value,
  };
}
