import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './StarRatings.module.css';

const FILLSTAR = 'fillstar';

const StarRatings = props => {
  const { className, rootClassName, rating = 0 } = props;
  const classes = classNames(rootClassName || css.root, className);

  return Array(5).fill(",").map((st, index) => {

    if ((+rating) > index) {
      return (
        <svg className={classes} fill="#000000" width="50px" height="50px" viewBox="0 0 1.5 1.5" xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24"><path d="M1.375 0.631c0.006 -0.031 -0.019 -0.069 -0.05 -0.069l-0.356 -0.05L0.806 0.188c-0.006 -0.013 -0.013 -0.019 -0.025 -0.025 -0.031 -0.019 -0.069 -0.006 -0.087 0.025L0.537 0.512 0.181 0.563c-0.019 0 -0.031 0.006 -0.037 0.019 -0.025 0.025 -0.025 0.063 0 0.087l0.256 0.25 -0.063 0.356c0 0.013 0 0.025 0.006 0.037 0.019 0.031 0.056 0.044 0.087 0.025l0.319 -0.169 0.319 0.169c0.006 0.006 0.019 0.006 0.031 0.006h0.013c0.031 -0.006 0.056 -0.037 0.05 -0.075l-0.063 -0.356 0.256 -0.25c0.013 -0.006 0.019 -0.019 0.019 -0.031z" fill='#EF8A64' /></svg>
      );
    } else {
      return <svg className={classes} width="50px" height="50px" viewBox="0 0 0.938 0.938" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="m0.436 0.078 -0.001 0.003L0.349 0.287a0.019 0.019 0 0 1 -0.016 0.011l-0.223 0.018 -0.003 0 -0.021 0.002 -0.019 0.002a0.019 0.019 0 0 0 -0.011 0.033l0.014 0.012 0.016 0.013 0.003 0.002 0.17 0.145a0.019 0.019 0 0 1 0.006 0.019l-0.052 0.217 -0.001 0.003 -0.005 0.02 -0.004 0.018a0.019 0.019 0 0 0 0.028 0.02l0.016 -0.01 0.018 -0.011 0.003 -0.002 0.191 -0.116a0.019 0.019 0 0 1 0.019 0l0.191 0.116 0.003 0.002 0.018 0.011 0.016 0.01a0.019 0.019 0 0 0 0.028 -0.02l-0.004 -0.018 -0.005 -0.02 -0.001 -0.003 -0.052 -0.217a0.019 0.019 0 0 1 0.006 -0.019L0.848 0.381l0.003 -0.002 0.016 -0.013 0.014 -0.012a0.019 0.019 0 0 0 -0.011 -0.033l-0.019 -0.002 -0.021 -0.002 -0.003 0 -0.223 -0.018a0.019 0.019 0 0 1 -0.016 -0.011L0.503 0.081l-0.001 -0.003 -0.008 -0.019 -0.007 -0.017a0.019 0.019 0 0 0 -0.035 0l-0.007 0.017 -0.008 0.019Zm0.033 0.085 -0.062 0.149a0.081 0.081 0 0 1 -0.069 0.05l-0.161 0.013 0.122 0.105a0.081 0.081 0 0 1 0.026 0.081l-0.037 0.157 0.138 -0.084a0.081 0.081 0 0 1 0.085 0l0.138 0.084 -0.037 -0.157a0.081 0.081 0 0 1 0.026 -0.081l0.122 -0.105 -0.161 -0.013a0.081 0.081 0 0 1 -0.069 -0.05l-0.062 -0.149Z" fill="#747474" /></svg>
    }

  })

};

StarRatings.defaultProps = {
  className: null,
  rootClassName: null,
  type: 'default',
};

StarRatings.propTypes = {
  className: string,
  rootClassName: string,
  type: string,
};

export default StarRatings;
