import React, { useEffect, useState } from 'react';
import moment from 'moment';
import classNames from 'classnames';
const ChatAPI = require('twilio-chat');
 import { Device } from '@twilio/voice-sdk';
import meetingImg from '../../assets/biketribe-brandImage-1500.jpg';
import { FormattedMessage } from '../../util/reactIntl';
import IconVideo from './IconVideo';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import css from './TwilioVideo.module.css';
import TwilioVoiceCall from '../../containers/MeetingPage/TwilioVoiceCall';
import Room from '../../containers/MeetingPage/TwilioVideo/components/Room/Room';
import Controls from '../../containers/MeetingPage/TwilioVideo/components/Controls/Controls';
import { SymblProvider } from '../../containers/MeetingPage/TwilioVideo/components/SymblProvider';
import useRoomState from '../../containers/MeetingPage/TwilioVideo/hooks/useRoomState/useRoomState';
import useVideoContext from '../../containers/MeetingPage/TwilioVideo/hooks/useVideoContext/useVideoContext';
import OutsideClickHandler from '../OutsideClickHandler/OutsideClickHandler';

const VideoChat = props => {
  const {
    txId,
    currentUser,
    currentRoom,
    onGetToken,
    onGetTokenByCall,
    isProvider,
    displayName,
    onInsertCollection,
    onManageDisableScrolling,
    openModal,
    currentTransaction,
    setOpenModal,
    history,
    modalForCountdown,
    closeTimer
  } = props;
  const { end, start } = (currentTransaction && currentTransaction.id && currentTransaction.booking.attributes) || {};
  const [connecting, setConnecting] = useState(false);
  console.log('connecting', connecting)
  const [errorMessage, setErrorMessage] = useState(null);
  const { roomState } = useRoomState({ onInsertCollection });
  const [roomName, setRoomName] = useState(currentRoom);
  const [userName, setUserName] = useState(displayName);
  const { connect, room } = useVideoContext();
  const [hasStarted, setHasStarted] = useState(false);
  const [isStarting, setIsStarting] = useState(false);
  const [messages, setMessages] = useState([]);
  const [channel, setChannel] = useState(null);
  const [text, setText] = useState('');
  const [isChatOpened, toggleChatWindow] = useState(false);
  const [showCC, toggleCC] = useState(false);
  const [endcall, setEndCall] = useState(false);
  const [timer, setTimer] = useState(60);

  const updateText = e => setText(e);
  let isMeetingTimePassed = true;
  const [disableMeetingButton, setdisableMeetingButton] = useState(true);
  
  useEffect(() => {
    return () => {
      if (typeof window !== 'undefined') {
        window.location = "/";
      }
    }
  }, [])
  useEffect(() => {
    if (end && start) {
      const nowTime = moment(new Date(), 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').unix();
      const startTimeStamp = moment(start, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').unix();
      const endTimeStamp = moment(end, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').unix();
      const sevenMinutesInSeconds = 7 * 60; // 7 minutes converted to seconds
      const meetingendgraceTimestamp = endTimeStamp + sevenMinutesInSeconds;

      if (meetingendgraceTimestamp > nowTime) {
        setTimeout(() => {
          console.log('here in end call');
          setEndCall(true);
        }, (meetingendgraceTimestamp - (nowTime + 2)) * 1000)
      }
      let timeDifferenceInSeconds = (startTimeStamp - nowTime) <= 0 ? 0 : (startTimeStamp - nowTime);
      const MeetingTimeAlreadyPassed = (endTimeStamp - nowTime) >= 0 ? false : true;
      console.log(MeetingTimeAlreadyPassed, endTimeStamp - nowTime, timeDifferenceInSeconds);
      if (!MeetingTimeAlreadyPassed) {
        setTimeout(() => {
          setdisableMeetingButton(false)
        }, timeDifferenceInSeconds * 1000)
      } else {
        // setjoinMeetinglinkDisabled(true)
        setdisableMeetingButton(true)
      }
      if (startTimeStamp <= nowTime && nowTime <= endTimeStamp) {
        setdisableMeetingButton(false);
      } else {
        setdisableMeetingButton(true);
      }

    }
  }, [end])

  
    

  useEffect(() => {
    if (modalForCountdown.open) {
      setOpenModal();
      const interval = setTimeout(() => {
        if (timer == 0) {
          // console.log('condition','looking up',interval);
          clearInterval(interval)
        } else {
          setTimer(timer - 1);
        }
      }, 1000);
    }
  }, [modalForCountdown, timer])

  const joinChannel = async channel => {
    if (channel.channelState.status !== 'joined') {
      await channel.join();
    }

    setChannel(channel);
    channel.on('messageAdded', function (message) {
      handleMessageAdded(message);
    });
  };

  const handleMessageAdded = message => {
    setMessages(messages => [...messages, message]);
  };

  const sendMessage = () => {
    if (text) {
      channel.sendMessage(String(text).trim());
      setText('');
    }
  };

  const onJoinRoom = async () => {
    console.log('connect')
    try {
      setConnecting(true);
      const getToken = await onGetToken();
      const token = getToken && getToken.twilioVideoToken;
      //  const voiceToken = getToken && getToken.twilioVoiceToken;
      const chatToken = getToken && getToken.twilioChatToken;
      if (roomState === 'disconnected' && !hasStarted && !isStarting) {
        setRoomName(currentRoom);
        setUserName(displayName);
        if (roomName && userName) {
          setIsStarting(true)
          connect(token)
          setIsStarting(false);
          setHasStarted(true);
          const client = await ChatAPI.Client.create(chatToken);

          client.on('tokenAboutToExpire', async () => {
            const getToken = await onGetToken();
            if (getToken && getToken.twilioChatToken) {
              client.updateToken(getToken.twilioChatToken);
            }
          });

          client.on('tokenExpired', async () => {
            const getToken = await onGetToken();
            if (getToken && getToken.twilioChatToken) {
              client.updateToken(getToken.twilioChatToken);
            }
          });

          client.on('channelJoined', async channel => {
            // getting list of all messages since this is an existing channel
            const newMessages = await channel.getMessages();
            setMessages(newMessages.items || []);
          });

          try {
            const channel = await client.getChannelByUniqueName(currentRoom);
            joinChannel(channel);
            setChannel(channel);
          } catch (err) {
            console.log(err);
            try {
              const channel = await client.createChannel({
                uniqueName: currentRoom,
                friendlyName: currentRoom,
              });

              joinChannel(channel);
            } catch {
              throw new Error('Unable to create channel, please reload this page');
            }
          }
        }
      }
      setConnecting(false);
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const startTimeStamp = moment(start, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').unix();
  const endTimeStamp = moment(end, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').unix();
  const nowTime = moment(new Date(), 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').unix();
  const isMeetingdisable = (endTimeStamp - nowTime) <= 0 || (startTimeStamp - nowTime) >= 0 ? false : true;
  
  
  const disconnectUpdate = () => {
    const startTimeStamp = moment(start, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').unix();
    const endTimeStamp = moment(end, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').unix();
    const nowTime = moment(new Date(), 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').unix();
    
    if (startTimeStamp <= nowTime && nowTime <= endTimeStamp) {
      if (roomState && currentUser && currentUser.id)
       {
        room.disconnect();
        if (typeof window !== 'undefined') {
          window.location = "/";
        }
      }
    } else {
      console.log('else part of disconnecting');
      setTimeout(() => {
        room.disconnect();
        if (typeof window !== 'undefined') {
          window.location = "/";
        }
      }, [1000]); 
    }
  }

  const onVoiceCall = async () => {
    const getToken = await onGetTokenByCall({});
    const voiceToken = getToken && getToken.twilioVoiceToken;
    if (voiceToken) {
      console.log('Calling...', voiceToken);
      const device = new Device(voiceToken, {
        logLevel: 1,
        // Set Opus as our preferred codec. Opus generally performs better, requiring less bandwidth and
        // providing better audio quality in restrained network conditions.
        codecPreferences: ["opus", "pcmu"],
      });
      console.log(device, '**** **** => device');
      // Device must be registered in order to receive incoming calls
      device.register();
      device.on("registered", function () {
        console.log("Twilio.Device Ready to make and receive calls!");
      });
      device.on("error", function (error) {
        console.log("Twilio.Device Error: " + error.message);
      });

    }
  }

  return (
    <div className={css.videoWrapper}>
      {roomState === 'disconnected' ? (
        <div className={css.prejoiningContainer}>
          <div className={css.meetingInitialContent}>
            <div className={css.joinMeetingSec}>
              <Button 
               onClick={onJoinRoom}
                  disabled={!isMeetingdisable} 
              >
                <FormattedMessage id="joinMeeting" /> <IconVideo type="VIDEO" />
              </Button>
              {connecting ? (
                <div className={css.ripple}>
                  <div></div>
                  <div></div>
                </div>
              ) : null}
            </div>
          </div>

          <div className={css.meetingImage}>
            <img src={meetingImg} alt="Join Meeting" />
          </div>
        </div>
      ) : <div
        className={classNames(
          css.postjoiningContainer
        )}
      >
        <div className={classNames(css.videoRoom, {
          [css.videoChat]: isChatOpened
        })}>
          <SymblProvider roomName={roomName}>
            <div className={css.roomWrapper}>
              <Room
                txId={txId}
                messages={messages}
                displayName={displayName}
                onUpdateText={updateText}
                onSendMessage={sendMessage}
                text={text}
                toggleChatWindow={toggleChatWindow}
                isChatOpened={isChatOpened}
                // showCC={showCC}
                transactionRole={isProvider ? 'provider' : 'customer'} 
              userId={currentUser && currentUser.id && currentUser.id.uuid} 
              />
            </div>
            {/* <ClosedCaptions /> */}
            <Controls
              toggleChatWindow={toggleChatWindow}
              isChatOpened={isChatOpened}
              // toggleCC={toggleCC}
              // showCC={showCC}
              endcall={endcall}
              history={history}
              disconnectUpdate={disconnectUpdate}
            />
          </SymblProvider>

        </div>
        <Modal
          id="meetingModal"
          isOpen={openModal == true}
          onClose={setOpenModal}
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <OutsideClickHandler onOutsideClick={() => openModal == true && setOpenModal()}>
            Heads up! Less than 5 minutes left on this call. Make them count!
          </OutsideClickHandler>
        </Modal>

        <Modal
          id="countdownMeetingModal"
          isOpen={modalForCountdown.open}
          onClose={closeTimer}
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <OutsideClickHandler onOutsideClick={() => modalForCountdown.open && closeTimer()}>
            There is {timer} seconds left! Let’s wrap it up.
          </OutsideClickHandler>
        </Modal>



        <div>

        </div>
      </div>}
    </div>
  );
};

export default VideoChat;
