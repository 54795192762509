
import { fetchPageAssets } from '../../ducks/hostedAssets.duck';

import { parse } from '../../util/urlHelpers';
export const ASSET_NAME = 'landing-page';




export const loadData = (params, search) => dispatch => {
  const pageAsset = { landingPage: `content/pages/${ASSET_NAME}.json` };
 
  return( dispatch(fetchPageAssets(pageAsset, true)));


};
