import React, { Component, useEffect, useState } from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';
import { useConfiguration } from '../../context/configurationContext';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';
import { AspectRatioWrapper, IconProfileCard, NamedLink, PrimaryButton, ResponsiveImage, StarRatings } from '../../components';
import { types as sdkTypes } from '../../util/sdkLoader';
import css from './ListingCard.module.css';
const MIN_LENGTH_FOR_LONG_WORDS = 10;

const getRatingNumber = (ratingText) => {
  return ratingText == "Five" ? 5 : ratingText == "Four" ? 4 : ratingText == "Three" ? 3 : ratingText == "Two" ? 2 : ratingText == "One" ? 1 : null
}


const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};
const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });
export const ListingCardComponent = props => {
  const config = useConfiguration();
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
    searchCategory,
    profilepage
  } = props;

  const { Money } = sdkTypes;
  const { MultiplePrice, categories } = listing.attributes.publicData;
  const [p, setp] = useState({});
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData } = currentListing.attributes;
  const slug = createSlug(title);
  const { noReviews, ratings
  } = publicData || {};

  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const firstImage = currentListing?.author?.profileImage
    ? currentListing?.author?.profileImage
    : null;
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];
  const { priceTitle } = priceData(new Money(100 * 100, config.currency), config.currency, intl);
  useEffect(() => {
    const amount = listing?.attributes?.price;
    if (searchCategory)
      listing?.attributes?.publicData?.MultiplePrice?.map(ele => {
        if (ele.key === searchCategory) {
          setp(priceData(new Money(ele.price, config.currency), config.currency, intl));
        }
      });
    else
      setp(priceData(amount, config.currency, intl));
  }, [searchCategory]);
  const setActivePropsMaybe = setActiveListing
    ? {
      onMouseEnter: () => setActiveListing(currentListing.id),
      onMouseLeave: () => setActiveListing(null),
    }
    : null;


  return (
    <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
      <div className={css.listCardWrapper}>
        <div className={css.flexsection}>
          <div className={css.leftLayout}>
            <div className={css.imageSection}>

              {profilepage ? <>
                <img src={author.profileImage?.attributes?.variants['square-small']?.url} alt="Image" /></> : <>
                <AspectRatioWrapper
                  className={css.aspectRatioWrapper}
                  width={aspectWidth}
                  height={aspectHeight}
                  {...setActivePropsMaybe}
                >
                  <LazyImage
                    rootClassName={css.rootForImage}
                    alt={title}
                    image={firstImage}
                    variants={variants}
                    sizes={renderSizes}
                  />
                </AspectRatioWrapper></>}



            </div>

            <div className={css.iconText}>
              {/* <StarRatings
                svgIconViewBox="0 0 40 37"
                svgIconPath="M20 0L26.113 11.5862L39.0211 13.8197L29.891 23.2138L31.7557 36.1803L20 30.4L8.2443 36.1803L10.109 23.2138L0.97887 13.8197L13.887 11.5862L20 0Z"
                starRatedColor="#ffb802"
                // starEmptyColor="#ffffff"
                rating={ratings}
                starDimension="25px"
                starSpacing="2px"
              /> */}
          
              <StarRatings className={css.ratingBox} rating={getRatingNumber(ratings)} />
              {noReviews ? <span>({noReviews})</span> : null}
            </div>
            <div className={css.requestButton}>
              <PrimaryButton>Request a Call</PrimaryButton>
            </div>
          </div>
          <div className={css.nameSection}>
            <div className={css.topNameSection}>
              <div>
                {showAuthorInfo ? (
                  <div className={css.authorInfo}>
                    <FormattedMessage id="ListingCard.author" values={{ authorName }} />
                  </div>
                ) : null}

              </div>
              <div className={css.price}>
                <div className={css.priceValue} title={priceTitle}>
                  {p?.formattedPrice}
                </div>
                {isBookingProcessAlias(publicData?.transactionProcessAlias) ? (
                  <div className={css.perUnit}>
                    <FormattedMessage
                      id="ListingCard.perUnit"
                      values={{ unitType: publicData?.unitType }}
                    />
                  </div>
                ) : null}
              </div>
            </div>
            <div className={css.titleWrapper}>
              <div className={css.profileTitle}>
                {richText(title, {
                  longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                  longWordClass: css.longWord,
                })}
              </div>
              <div className={css.locationWrapper}>
                <IconProfileCard type="location" />
                {publicData.city},
                {publicData.state}
              </div>
            </div>
            <div className={css.profileDescription}>
              {publicData.headline}
            </div>
            <div className={css.specialitySection}>
              <div className={css.categoryHeading}> Categories:</div>
              <div className={css.categoiresSection}>
                <ul>
                  {publicData.categories?.map((item) => {
                    return (
                      <li>
                        {item}
                      </li>
                    )
                  })}
                </ul>
              </div>
              {/* <div className={css.keyOptions}>{publicData.keyExpertise}</div>  */}
            </div>
          </div>
        </div>
      </div>
    </NamedLink>
  );
};
ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};
ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,
  // Responsive image sizes hint
  renderSizes: string,
  setActiveListing: func,
};
export default injectIntl(ListingCardComponent);