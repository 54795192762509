import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { styled } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import MainParticipant from '../MainParticipant/MainParticipant';
import useSymblContext from '../../hooks/useSymblContext/useSymblContext';
import { Button } from '../../../../../components';

import css from './Room.module.css';
// import { SpeechProvider } from '@speechly/react-client';
// import { useSpeechContext } from '@speechly/react-client';
// import App from './App'
const Container = styled('div')(({ theme }) => ({
  position: 'relative',
  height: '100%',
  gridTemplateColumns: `${theme.sidebarWidth}px 1fr`,
  gridTemplateAreas: '". participantList transcript"',
  gridTemplateRows: '100%',
  [theme.breakpoints.down('xs')]: {
    gridTemplateAreas: '"participantList" "."',
    gridTemplateColumns: `auto`,
    gridTemplateRows: `calc(100% - ${theme.sidebarMobileHeight +
      12}px) ${theme.sidebarMobileHeight + 6}px`,
    gridGap: '6px',
  },
}));

export default function Room(props) {
  const {
    txId,
    messages,
    displayName,
    toggleChatWindow,
    isChatOpened,
    text,
    onSendMessage,
    onUpdateText,
    showCC,
    transactionRole,
    userId,
     onInsertCollection,
  } = props;
 console.log('rooms', props)
 console.log('transactionRole', transactionRole)
  const { isStarting } = useSymblContext();
  // const { listening, segment, attachMicrophone, start, stop } = useSpeechContext();

  // if (listening) {
  //    stop();
  // } else {
  //   console.log('here');
  //    attachMicrophone();
  //    start();
  // }
  // console.log('segment','ram',segment);
  return (
    <Container>
      {isStarting ? <CircularProgress /> : undefined}

      <MainParticipant
        txId={txId}
        // showCC={showCC}
         onInsertCollection={onInsertCollection}
        transactionRole={transactionRole}
        userId={userId}
      />
      {/* <SpeechProvider
            appId="8adf1b32-a380-44c3-918b-ca97b8ca9f89"
            debug
            logSegments> <App />
          </SpeechProvider> */}


      {isChatOpened ? (
        <div className={css.rightSide}>
          <span
            className={css.cross}
            onClick={() => {
              toggleChatWindow(!isChatOpened);
            }}
          >
            &times;
          </span>
          <div className={css.chatContainer}>
            <div className={css.chatArea}>
              <button
                className={css.videoActionButton}
                onClick={() => toggleChatWindow(!isChatOpened)}
                data-tip
                data-for="chatTooltip"
              >
                Chat
              </button>
              {messages && Array.isArray(messages)
                ? messages.map((message, i) => (
                  <>
                    <div
                      className={classNames(css.messageWrapper, {
                        [css.ownMessage]: message?.author === displayName,
                        [css.otherMessage]: message?.author !== displayName,
                      })}
                      key={i}
                    >
                      <div
                        className={classNames(css.messageContent, {
                          [css.ownMessageContent]: message?.author === displayName,
                        })}
                      >
                        <div className={css.messageBoxWrapper}>
                          <div className={css.messageBodyBox}>
                            <div className={css.rightBody}>
                              <h4>{message.author}</h4>
                              <div className={css.date}>
                                {moment(message.dateCreated).fromNow()}
                              </div>
                            </div>
                            <div className={css.msg}> {message.body} </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))
                : null}
            </div>
            <div className={css.messageInputArea}>
              <div className={css.msgArea}>
                <input
                  type="text"
                  placeholder="Type Message"
                  onChange={e => onUpdateText(e.target.value)}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      e.stopPropagation();
                      onSendMessage(e);
                    }
                  }}
                  value={text}
                />
                <Button className={css.sendbtn} type="submit" onClick={onSendMessage}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    style={{ fill: 'none' }}
                  >
                    <line x1="22" y1="2" x2="11" y2="13" />
                    <polygon points="22 2 15 22 11 13 2 9 22 2" />
                  </svg>
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </Container>
  );
}
