import { parse } from 'querystring';
import { encrypt, fetchUrl } from '../../util/api';
import { fetchPageAssets } from '../../ducks/hostedAssets.duck';

export const TOS_ASSET_NAME = 'terms-of-service';
export const PRIVACY_POLICY_ASSET_NAME = 'privacy-policy';

export const PROVIDER_REQUEST = 'app/auth/PROVIDER_REQUEST';
export const PROVIDER_SUCCESS = 'app/auth/PROVIDER_SUCCESS';
export const PROVIDER_ERROR = 'app/auth/PROVIDER_ERROR';

const initialState = {
  ischeckprovider: false,
  isdecrypted: null,
}
export default function AuthenticationPagereducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case PROVIDER_REQUEST:
      return {};
    case PROVIDER_SUCCESS:
      console.log(payload, '^^^^ ^^^^ => payload');
      
      return {  isdecrypted: payload.eamilDecrypted };
    case PROVIDER_ERROR:
      return { ...state, confirmInProgress: false, providerError: payload };

    default:
      return state;
  }
}

export const providerRequest = () => ({ type: PROVIDER_REQUEST });
export const providerSuccess = (res) => ({ type: PROVIDER_SUCCESS, payload: res });
export const providerError = error => ({ type: PROVIDER_ERROR, payload: error, error: true });
export const authenticationInProgress = state => {
  const { loginInProgress, logoutInProgress, signupInProgress } = state.auth;
  return loginInProgress || logoutInProgress || signupInProgress;
};

export const providerLogin = (res) => (dispatch, getState, sdk) => {
  return encrypt()
    .then((res) => {
      dispatch(providerSuccess({ provider: res.checkprovider, eamilDecrypted: res.decrypted }))
    })
};



export const fetchAutoLoginUrl = (params) => (dispatch) => {
  return fetchUrl(params).then(res => {
    const inviteEmail = res.responseData.inviteEmail
    dispatch(providerSuccess({  eamilDecrypted: inviteEmail}))
    return res;
  }).catch((e) => {
    console.log(e, '^^^^ ^^^^ => e');
    //return storableError(e)
  });
}



export const loadData = (params, search) => async (dispatch) => {
  const pageAsset = {
    termsOfService: `content/pages/${TOS_ASSET_NAME}.json`,
    privacyPolicy: `content/pages/${PRIVACY_POLICY_ASSET_NAME}.json`,
  };

  const searchValues = search && search.length ? parse(search.slice(1)) : {};
  if (searchValues && searchValues.code) {
    await dispatch(fetchAutoLoginUrl({ id: searchValues.id }));
  }
  return (dispatch(fetchPageAssets(pageAsset, true)))
};
