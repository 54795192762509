import React from 'react';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { arrayOf, string } from 'prop-types';
import classNames from 'classnames';
import { Avatar, ReviewRating, UserDisplayName } from '../../components';
import { propTypes } from '../../util/types';
import Slider from "react-slick";
import css from './Reviews.module.css';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="61" height="61" viewBox="0 0 61 61" fill="none">
        <path d="M30.5 55.9163C44.5373 55.9163 55.9167 44.5369 55.9167 30.4997C55.9167 16.4624 44.5373 5.08301 30.5 5.08301C16.4628 5.08301 5.08337 16.4624 5.08337 30.4997C5.08337 44.5369 16.4628 55.9163 30.5 55.9163Z" stroke="#445463" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M21.6041 30.5H36.8541" stroke="#445463" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M31.7709 38.125L39.3959 30.5L31.7709 22.875" stroke="#445463" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </div>
  );
}

var settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  initialSlide: 0,
  nextArrow: <SampleNextArrow/>,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

const SliderReviews = ({ reviews = [], intl }) => {
  return <div className={css.reviewSlider}>
    <Slider {...settings}>
      {reviews.length && reviews.map(review =>
        <div>
          <div className={css.reviewSection}>
            <div className={css.reviewBox}>
              <div className={css.avatarImage}>
                <Avatar className={css.reviewAvatar} user={review.author} />
              </div>
              <div className={css.reviewRatingBox}>
                <div className={css.reviewStar} >
                  <ReviewRating
                    rating={review.attributes?.rating}
                    className={css.desktopReviewRating}
                    reviewStarClassName={css.reviewRatingStar}
                  />
                </div>
                <ReviewRating
                  rating={review.attributes?.rating}
                  className={css.mobileReviewRating}
                  reviewStarClassName={css.reviewRatingStar}
                />
                <div className={css.reviewDetail}>
                  <p className={css.reviewContent}>{review.attributes?.content}</p>
                </div>
                <p className={css.reviewInformation}>
                  <UserDisplayName user={review.author} intl={intl} />
                  {/* <span className={css.separator}>•</span> */}
                  {/* {dateString} */}
                  {/* <span className={css.desktopSeparator}>•</span> */}
                  {/* <span className={css.desktopReviewRatingWrapper}>

            </span> */}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </Slider>
  </div>
}

const Review = props => {
  const { review, intl } = props;

  const date = review.attributes.createdAt;
  const dateString = intl.formatDate(date, { month: 'long', year: 'numeric' });

  return (
    <div className={css.reviewSection}>
      <div className={css.reviewBox}>
        <div className={css.avatarImage}>
          <Avatar className={css.reviewAvatar} user={review.author} />
        </div>
        <div className={css.reviewRatingBox}>
          <div className={css.reviewStar} >
            <ReviewRating
              rating={review.attributes?.rating}
              className={css.desktopReviewRating}
              reviewStarClassName={css.reviewRatingStar}
            />
          </div>
          <ReviewRating
            rating={review.attributes.rating}
            className={css.mobileReviewRating}
            reviewStarClassName={css.reviewRatingStar}
          />
          <div className={css.reviewDetail}>
            <p className={css.reviewContent}>{review.attributes.content}</p>
          </div>
          <p className={css.reviewInformation}>
            <UserDisplayName user={review.author} intl={intl} />
            {/* <span className={css.separator}>•</span> */}
            {/* {dateString} */}
            {/* <span className={css.desktopSeparator}>•</span> */}
            {/* <span className={css.desktopReviewRatingWrapper}>

            </span> */}
          </p>
        </div>
      </div>
    </div>
  );
};

Review.propTypes = {
  review: propTypes.review.isRequired,
  intl: intlShape.isRequired,
};

const ReviewsComponent = props => {
  const { className, rootClassName, reviews, intl, typeSlider } = props;
  const classes = classNames(rootClassName || css.root, className);

  if (typeSlider) {
    return <SliderReviews reviews={reviews} intl={intl} />
  }


  return (
    <ul className={classes}>
      {reviews.map(r => {
        return (
          <li key={`Review_${r.id.uuid}`} className={css.reviewItem}>
            <Review review={r} intl={intl} />
          </li>
        );
      })}
    </ul>
  );
};

ReviewsComponent.defaultProps = {
  className: null,
  rootClassName: null,
  reviews: [],
};

ReviewsComponent.propTypes = {
  className: string,
  rootCalssName: string,
  reviews: arrayOf(propTypes.review),

  // from injectIntl
  intl: intlShape.isRequired,
};

const Reviews = injectIntl(ReviewsComponent);

export default Reviews;
