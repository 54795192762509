import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconProfileCard.module.css';


const PLUSICON = 'plus';
const STARICON = 'star';
const LOCATION = 'location';
const EDIT = 'edit';
const REVIEWSTAR = 'review';
const FACEBOOK = 'facebook';
const TWITTER = 'twitter';
const LINKEDIN = 'linkedin';
const DELETE = 'delete';
const IconProfileCard = props => {
  const { className, rootClassName, type } = props;
  const classes = classNames(rootClassName || css.root, className);

  switch (type) {
    case PLUSICON:
      return (
        <svg fill="#fff" width="15px" height="15px" viewBox="-0.056 0 0.356 0.356" xmlns="http://www.w3.org/2000/svg" class="cf-icon-svg"><path d="M0.238 0.172a0.019 0.019 0 0 1 -0.019 0.019H0.141v0.078a0.019 0.019 0 0 1 -0.039 0v-0.078H0.025a0.019 0.019 0 1 1 0 -0.039h0.078V0.075a0.019 0.019 0 0 1 0.039 0v0.078h0.078a0.019 0.019 0 0 1 0.019 0.019z" /></svg>
      );
    case STARICON:
      return (
        <svg width="30px" height="30px" viewBox="0 0 1.53 1.53" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" class="iconify iconify--twemoji" preserveAspectRatio="xMidYMid meet"><path fill="#FFAC33" d="M1.16 1.472c-0.017 0 -0.034 -0.005 -0.049 -0.016L0.765 1.208l-0.346 0.248a0.084 0.084 0 0 1 -0.098 0 0.084 0.084 0 0 1 -0.031 -0.093l0.129 -0.416 -0.343 -0.241a0.084 0.084 0 0 1 -0.03 -0.094 0.084 0.084 0 0 1 0.079 -0.058L0.55 0.552l0.135 -0.406a0.084 0.084 0 0 1 0.159 0L0.978 0.552l0.427 0.001a0.084 0.084 0 0 1 0.049 0.152l-0.343 0.241 0.129 0.416a0.084 0.084 0 0 1 -0.031 0.093 0.083 0.083 0 0 1 -0.049 0.016z" /></svg>
      );
    case LOCATION:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="29" viewBox="0 0 24 29" fill="none">
          <path d="M12 13.9437C11.337 13.9437 10.7011 13.6331 10.2322 13.0803C9.76339 12.5274 9.5 11.7776 9.5 10.9957C9.5 10.2138 9.76339 9.46399 10.2322 8.91113C10.7011 8.35826 11.337 8.04767 12 8.04767C12.663 8.04767 13.2989 8.35826 13.7678 8.91113C14.2366 9.46399 14.5 10.2138 14.5 10.9957C14.5 11.3828 14.4353 11.7662 14.3097 12.1239C14.1841 12.4815 13.9999 12.8065 13.7678 13.0803C13.5356 13.354 13.26 13.5712 12.9567 13.7193C12.6534 13.8675 12.3283 13.9437 12 13.9437ZM12 2.74121C10.1435 2.74121 8.36301 3.61088 7.05025 5.15889C5.7375 6.70691 5 8.80647 5 10.9957C5 17.1866 12 26.3255 12 26.3255C12 26.3255 19 17.1866 19 10.9957C19 8.80647 18.2625 6.70691 16.9497 5.15889C15.637 3.61088 13.8565 2.74121 12 2.74121Z" fill="#445463" fillOpacity="0.5" />
        </svg>

      );
    case EDIT:
      return (
        <svg width="22px" height="22px" viewBox="0 0 0.48 0.48" xmlns="http://www.w3.org/2000/svg" fill="none"><g fill="#7040ff"><path fillRule="evenodd" d="M0.343 0.03A0.052 0.052 0 0 1 0.417 0.024l0.021 0.018a0.052 0.052 0 0 1 0.006 0.074l-0.171 0.204a0.142 0.142 0 0 1 -0.045 0.036l-0.077 0.039a0.022 0.022 0 0 1 -0.032 -0.027l0.025 -0.083a0.142 0.142 0 0 1 0.027 -0.05l0.171 -0.204zm0.045 0.028a0.007 0.007 0 0 0 -0.011 0.001l-0.016 0.019 0.032 0.027 0.016 -0.019a0.007 0.007 0 0 0 -0.001 -0.011l-0.021 -0.018zm-0.023 0.082 -0.032 -0.027 -0.126 0.15a0.098 0.098 0 0 0 -0.019 0.034L0.178 0.33l0.03 -0.015a0.097 0.097 0 0 0 0.031 -0.024l0.126 -0.15z" clipRule="evenodd" /><path d="M0.098 0.105a0.022 0.022 0 0 0 -0.022 0.022v0.285c0 0.012 0.01 0.022 0.022 0.022h0.285a0.022 0.022 0 0 0 0.022 -0.022V0.27A0.022 0.022 0 0 1 0.45 0.27v0.142A0.068 0.068 0 0 1 0.383 0.48h-0.285A0.068 0.068 0 0 1 0.03 0.412v-0.285A0.068 0.068 0 0 1 0.098 0.06H0.18a0.022 0.022 0 0 1 0 0.045H0.098z" /></g></svg>
      );
    case REVIEWSTAR:
      return (
        <svg width="24" height="24" viewBox="0 0 1.08 1.08" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" class="iconify iconify--twemoji"><path fill="#FFAC33" d="M.819 1.039a.066.066 0 0 1-.035-.011L.54.853l-.244.175a.059.059 0 0 1-.069 0A.059.059 0 0 1 .205.962L.296.668.054.498A.059.059 0 0 1 .033.432.059.059 0 0 1 .089.391L.388.39.483.104a.059.059 0 0 1 .112 0L.69.39h.301a.059.059 0 0 1 .035.107l-.242.17.091.294a.059.059 0 0 1-.022.066.059.059 0 0 1-.035.011z" /></svg>
      );
    case FACEBOOK:
      return (
        <svg fill="#fff" width="24px" height="24px" viewBox="-0.228 -0.065 0.78 0.78" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin" class="jam jam-facebook"><path d="M0.066 0.126v0.089H0.001v0.109h0.065v0.325H0.201V0.324h0.09s0.008 -0.052 0.013 -0.11H0.201V0.14c0 -0.011 0.015 -0.026 0.029 -0.026h0.073V0H0.204c-0.141 0 -0.138 0.109 -0.138 0.126z" /></svg>
      );
    case TWITTER:
      return (
        <svg fill="#FFF" width="24px" height="24px" viewBox="0 0 0.78 0.78" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="M0.715 0.189a0.276 0.276 0 0 1 -0.077 0.021 0.134 0.134 0 0 0 0.059 -0.074 0.267 0.267 0 0 1 -0.085 0.033 0.133 0.133 0 0 0 -0.228 0.122 0.378 0.378 0 0 1 -0.275 -0.139 0.135 0.135 0 0 0 -0.018 0.067 0.133 0.133 0 0 0 0.059 0.111 0.132 0.132 0 0 1 -0.06 -0.017v0.002a0.133 0.133 0 0 0 0.107 0.13 0.128 0.128 0 0 1 -0.036 0.006 0.159 0.159 0 0 1 -0.025 -0.002 0.134 0.134 0 0 0 0.124 0.092A0.267 0.267 0 0 1 0.098 0.596a0.258 0.258 0 0 1 -0.033 -0.002 0.376 0.376 0 0 0 0.204 0.06A0.377 0.377 0 0 0 0.65 0.275v-0.017a0.274 0.274 0 0 0 0.065 -0.069Z" /></svg>
      );
    case LINKEDIN:
      return (
        <svg fill="#FFF" width="24px" height="24px" viewBox="-0.065 -0.065 0.78 0.78" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin" class="jam jam-linkedin"><path d="M0.649 0.381v0.24h-0.139v-0.224c0 -0.056 -0.02 -0.095 -0.07 -0.095 -0.038 0 -0.061 0.026 -0.071 0.051 -0.004 0.009 -0.005 0.021 -0.005 0.034v0.234h-0.139s0.002 -0.379 0 -0.418h0.139v0.059l-0.001 0.001h0.001v-0.001c0.018 -0.028 0.051 -0.069 0.125 -0.069 0.091 0 0.16 0.06 0.16 0.188zM0.079 0.001C0.031 0.001 0 0.032 0 0.073c0 0.04 0.03 0.072 0.077 0.072h0.001c0.049 0 0.079 -0.032 0.079 -0.072C0.156 0.032 0.126 0.001 0.079 0.001zM0.008 0.621h0.139V0.202H0.008v0.418z" /></svg>
      );
    case DELETE:
      return (
        <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 4a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v2h4a1 1 0 1 1 0 2h-1.069l-.867 12.142A2 2 0 0 1 17.069 22H6.93a2 2 0 0 1-1.995-1.858L4.07 8H3a1 1 0 0 1 0-2h4V4zm2 2h6V4H9v2zM6.074 8l.857 12H17.07l.857-12H6.074zM10 10a1 1 0 0 1 1 1v6a1 1 0 1 1-2 0v-6a1 1 0 0 1 1-1zm4 0a1 1 0 0 1 1 1v6a1 1 0 1 1-2 0v-6a1 1 0 0 1 1-1z" fill="#0D0D0D" /></svg>
      );
  }
};

IconProfileCard.defaultProps = {
  className: null,
  rootClassName: null,
  brand: 'default',
};

IconProfileCard.propTypes = {
  className: string,
  rootClassName: string,
  brand: string,
};

export default IconProfileCard;
